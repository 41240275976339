import Top from "@/components/layout/Top.vue";
import SlideBar from "@/components/layout/SlideBar.vue";
import ContentArea from "@/components/layout/ContentArea.vue";
import Tabs from "@/components/layout/Tabs.vue";
import BookEffect from "@/components/utils/bookEffect/BookEffect.vue";
import MainUtil,{IMainDataObj} from "@/components/layout/ts/main/mainUtil";
import {useStore} from "vuex";
import {ref, reactive, toRefs, onMounted,onBeforeMount, onActivated, computed, watch, getCurrentInstance, defineComponent, nextTick, provide} from 'vue';
import config from "@/utils/config";
import {useRoute} from "vue-router";
import Theme from "@/mythemes/theme";
import screenfull from "screenfull";
export default defineComponent({
    name: "Main",
    beforeRouteEnter(to:any,from:any,next:any){
        next((curProxyInst:any)=>{
            if(!from.name && '/welcome'!=to.path){
                //经过验证，发现刷新的时候from.name是undefined，所以可以用来区分是否刷新，刷新的时候把当前路由存起来，当前路由：to.path
                sessionStorage.setItem('refreshRouteInfo',JSON.stringify({title:to.meta.title,path:to.path}));
            }
        })
    },
    setup(){
        const topRef=ref(null) as any;
        const slideBarRef=ref(null) as any;
        const mainContentRef=ref(null) as any;
        const contentRef=ref(null) as any;
        const appInstant = getCurrentInstance();
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('mainComp', proxy);
        let store = useStore();
        let route = useRoute();
        const dataObj:IMainDataObj=reactive<IMainDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            contentClassFlag: false,
            isEffectOver: !(utils.Tools.isAnimationOpen('mainAnimation') && config.flag1),
            bookEffectParams:{
                inst:proxy
            },
            prop:0,//二级路由是否铺满整个content，prop=2且需要显示layer的时候铺满，默认为0不铺满，只铺满content的右侧.
            showLayer: sessionStorage.getItem("showLayer"),//是否显示layer
            otherParams:{
                flag1:config.flag1,
                live2d:{
                    configVisible:false,//设置play弹出框控制标识
                    closeType:'2',//关闭类型0-不关闭；1-关闭；2-永久关闭；
                    currentLive2d:'shizuku',
                    data:[
                        {value:'shizuku',label:'萌娘',folderName:'shizuku'},{value:'chitose',label:'小帅哥',folderName:'chitose'},
                        {value:'Gantzert_Felixander',label:'剑客',folderName:'gf'},{value:'Epsilon2.1',label:'美女1',folderName:'epsilon2_1'},
                        {value:'haru01',label:'美女2',folderName:'haru_1'},{value:'haru02',label:'美女3',folderName:'haru_2'},
                        {value:'izumi',label:'美女4',folderName:'izumi'},{value:'hibiki',label:'女学生',folderName:'hibiki'},
                        {value:'koharu',label:'小可爱（女）',folderName:'koharu'}, {value:'haruto',label:'小可爱（男）',folderName:'haruto'},
                        {value:'miku',label:'初音',folderName:'miku'},{value:'nico',label:'小阿狸',folderName:'nico'},
                        {value:'ni-j',label:'动漫1',folderName:'ni-j'},{value:'nietzche',label:'动漫2',folderName:'nietzsche'},
                        {value:'nipsilon',label:'动漫3',folderName:'nipsilon'},{value:'nito',label:'动漫4',folderName:'nito'},
                        {value:'tsumiki',label:'绿毛妹妹',folderName:'tsumiki'},{value:'unitychan',label:'金龟子妹妹',folderName:'unitychan'},
                        {value:'z16',label:'圣职者妹妹',folderName:'z16'},{value:'tororo',label:'白猫',folderName:'tororo'},
                        {value:'hijiki',label:'黑猫',folderName:'hijiki'},{value:'wanko',label:'茶杯犬',folderName:'wanko'},
                    ],
                },
                menuIcon:'iconfont icon-a-cebianlanfenleizhedie',//折叠/展开图标
                showTop:localStorage.getItem('showTop')?(localStorage.getItem('showTop')=='false'?false:true):true,//是否展示顶部(默认展示)
                splitParams:{
                    lwidth:'13%',
                    lMinWidth:54,
                }
            }
        })
        //页面激活事件，从锁屏页解锁之后会再次激活main页面，在这里完成锁屏时页面状态恢复
        onActivated( async()=>{
            dataObj.prop=route.meta.prop;
            await dataObj.utilInst.recoverLockScreenState();//如果是从锁屏页面通过解锁进入main，则改变锁屏对象lockScreen的锁屏状态，然后跳转到锁屏时的路由进行路由恢复
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new MainUtil(proxy,appInstant,dataObj);
            //tmd，如果我们的css中有自定义颜色，在主题切换的时候为了适应对应的主题，就会写几份这样的颜色，但是默认取不到theme.ts中定义的变量，非要手动的切换一下才能调用到
            let curTheme=!localStorage.getItem('curTheme')?'defaultTheme':localStorage.getItem('curTheme') as string;
            Theme.switchTheme(curTheme);
        })

        onMounted(async ()=>{
            await nextTick(async () => {
                await dataObj.utilInst.reCreateWebSocket(); //如果刷新页面了，会造成$socket丢失，重新创建
                //有两种情况sessionStorage里面有refreshRouteInfo信息
                //1、结合着头部的beforeRouteEnter来用的，如果是刷新，那么需要跳转到之前打开的路由，而不是统一跳转到welcome去
                //2、如果从layer过来的，sessionStorage里面也有可能有refreshRouteInfo信息
                let refreshRouteInfo:string=sessionStorage.getItem('refreshRouteInfo')as string;
                if(refreshRouteInfo){
                    sessionStorage.removeItem('refreshRouteInfo');//必须移除，否则直接关闭指定tab之后，只剩下welcome，再刷新整个页面，还会打开sessionStorage中的页面
                    await proxy.$router.push({path:JSON.parse(refreshRouteInfo).path});
                    await store.dispatch("recoverViews", [JSON.parse(refreshRouteInfo)]);//恢复指定tabs和/welcome
                }else{
                    await proxy.$router.push({path: "/welcome"});//刚刚进入main的时候，跳转到welcome这个路由
                    //之前只要进入main，就清除所有已访问tabs，现在不清除了，参见layer中点击某项事件里面的描述
                    // await store.dispatch("closeAllVisitedTabsView");//关闭所有tabs，只保留/welcome
                    await store.dispatch("recoverViews",[]);//recoverViews会保留/welcome，相当于每次进入main都打开/welcome
                }
                dataObj.utilInst.initLive2d();//初始化看板
            });
        })
        //---------------------------computed------------
        const slideClass=computed(()=>{//根据是否折叠左边菜单，动态计算左边菜单大小
            return {
                slide_open: !dataObj.contentClassFlag,
                slide_collapse: dataObj.contentClassFlag
            }
        })
        const effectOver=()=>{
            dataObj.isEffectOver=true;
        }
        //--------------------------------右侧操作栏
        //全屏
        const fullScreen=async ()=>{
            if (!screenfull.isEnabled) utils.Tools.info({message: proxy.$t('fullScreenMsg')});
            else await screenfull.toggle();
        }
        //返回layer菜单列表
        const backMenu=async ()=>{
            //必须清除MenuIterm的userMenus，假如A有三级菜单，你通过layer点击A进入main，main左侧通过MenuIterm会展示A的子菜单（2级）；
            //当你通过top触发这里的事件再次进入layer，这个时候，你在layer不点其它，再次点击A，又进入刚才的页面，会发现菜单展示异常了，
            //你在这里清除掉userMenus就显示正常了。此外，如果组件铺满整个content，就没有slideBar，所以需要判断一下slideBarRef是否为空
            if(slideBarRef && slideBarRef.value)slideBarRef.value.menuItemRef.userMenus=[];//fuck,slideBarRef不为空，但是slideBarRef.value可能为null
            await proxy.$router.replace({path: "/layer"});
        }
        //退出登录
        const logOut=()=>{
            utils.Tools.configBox({
                message: proxy.$t('login.loginOutNote'),
                sureFn: async () => {
                    proxy.$loading({lock: true,text: proxy.$t('loadMsg'),spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
                    //退出登录的时候，只需要把userInfo去掉，不能把token去掉，否则报错
                    sessionStorage.removeItem("userInfo");
                    let res = await utils.Api.loginOut();
                    if(res.result){
                        utils.Tools.success({message: proxy.$t('login.loginOutMsg')});
                        location.reload();
                    }else{
                        utils.Tools.error();
                    }
                }
            });
        }
        //显示顶部工具栏
        const showTop=()=>{
            dataObj.otherParams.showTop=true;
            localStorage.setItem('showTop','true');
            utils.Const.topHeight=56;
        }
        //锁屏
        const lockScreen=()=>{
            let lockScreenObject:ILockScreen={lockScreen:true,lockScreenTime:new Date(),lockScreenState:'locked',currentRoute:proxy.$router.currentRoute.value.path}
            sessionStorage.setItem("lockScreen", JSON.stringify(lockScreenObject));
            proxy.$router.push({path:'/lockScreen'});
        }
        //折叠/展开左边栏
        const collapseLeft=()=>{
            //当从layer打开的末级菜单prop=2，即展示铺满整个content区域，不需要slidebar的时候，slideBarRef.value、dataObj.refMap.get('split')为空
            if (dataObj.otherParams.menuIcon == "iconfont icon-a-cebianlanfenleizhedie") {//折叠动作
                dataObj.otherParams.menuIcon = "iconfont icon-a-fenleizhediecebianlan";
                if(slideBarRef && slideBarRef.value)slideBarRef.value.isCollapse = true;
                dataObj.contentClassFlag = true;
                if(dataObj.refMap.get('split'))dataObj.refMap.get('split').cfgWidth({lwidth:'59px'});
            } else {//打开动作
                dataObj.otherParams.menuIcon = "iconfont icon-a-cebianlanfenleizhedie";
                if(slideBarRef && slideBarRef.value)slideBarRef.value.isCollapse = false;
                dataObj.contentClassFlag = false;
                if(dataObj.refMap.get('split'))dataObj.refMap.get('split').cfgWidth({lwidth:dataObj.otherParams.splitParams.lwidth});
            }
        }

        //更改看板娘
        const configLive2d=()=>{
            dataObj.utilInst.configLive2d();
        }
        return{
            ...toRefs(dataObj),topRef,slideBarRef,mainContentRef,contentRef,slideClass,effectOver,
            fullScreen,backMenu,logOut,showTop,lockScreen,collapseLeft,configLive2d
        }
    },

    components: {
        Top,
        SlideBar,
        ContentArea,
        Tabs,
        BookEffect
    }
});